@import "./styles/_styles.scss";
.articleQuote {
  text-align: center;
  max-width: 100%;
  width: 1096px;
  padding: 32px 0;
  margin: 0 auto;
}
.quote {
  margin: 0;
  font-style: italic;
  font-weight: normal;
  font-size: 40px;
  line-height: 52px;
}
.authorName {
  margin: 0;
  margin-top: 16px;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  &::before {
    content: '\2015 \2009';
  }
}
@media screen and(max-width:768px) {
  .articleQuote {
    padding: 20px 0;
  }
  .articleQuote {
    width: 639px;
  }
  .quote {
    font-size: 32px;
    line-height: 40px;
  }
}
@media screen and(max-width:576px) {
  .articleQuote {
    padding: 20px 15px;
  }
  .quote {
    font-size: 24px;
    line-height: 32px;
  }
  .authorName {
    font-size: 20px;
    line-height: 28px;
  }
  .authorName {
    margin-top: 12px;
  }
}
