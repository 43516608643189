@import "./styles/_styles.scss";
.tag {
  cursor: pointer;
  background-color: white;
  border-radius: 50px;
  width: fit-content;
  white-space: nowrap;
  display: block;
  text-decoration: none;
  &:not(:last-of-type) {
    margin-right: 24px;
  }
}
.tagUrl {
  color: black;
  font-weight: bold;
  text-decoration: none;
  padding: 8px 20px 7px;
  letter-spacing: 0;
  margin: 0;
}
.normal {
  .tagUrl {
    font-size: 14px;
    line-height: 22px;
  }
}
.large {
  .tagUrl {
    font-size: 16px;
    line-height: 24px;
  }
}
