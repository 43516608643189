@import "./styles/_styles.scss";
.paragraph {
  width: 870px;
  max-width: 100%;
  margin: 0 auto;
  padding: 28px 0;
  a{
    color: inherit;
  }
}
.h2 {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 56px;
  margin-top: 0;
  &.paragraphNonH3 {
    margin-bottom: 24px !important;
  }
}
.h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  margin-top: 0;
  margin-bottom: 16px;
}
.description {
  margin: 0;
  p {
    margin: 0;
  }
  font-size: 20px;
  line-height: 28px;
  ul {
    margin: 0;
    padding-left: 30px;
  }
}

@media screen and(max-width:768px) {
  .paragraph {
    padding: 20px 0;
    width: 533px;
  }
  .h2 {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 32px;
  }
  .h3 {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 28px;
  }
  .description {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and(max-width:576px) {
  .paragraph {
    padding: 16px 15px;
  }
}
