@import "./styles/_styles.scss";
.videoPlayerWrap {
  margin: 32px 173px 32px 173px;
  width: 1094px;
  position: relative;
  max-width: 100%;
  margin: 0 auto;
}
.vimeoVideo {
  position: relative;
  max-height: 618px;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}

@media screen and(max-width:768px) {
  .videoPlayerWrap {
    padding: 0 24px;
    margin: 20px auto;
  }
}
@media screen and(max-width:576px) {
  .videoPlayerWrap {
    padding: 0 15px;
    margin: 20px auto;
  }
}
