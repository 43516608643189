@import "./styles/_styles.scss";
.articleTextLarge {
  max-width: 100%;
  width: 870px;
  margin: 0 auto;
  font-size: 40px;
  line-height: 52px;
  font-weight: 400;
  p {
    margin: 0;
  }
  @media screen and(max-width:768px) {
    font-size: 24px;
    line-height: 32px;
    width: 533px;
  }
  @media screen and(max-width:576px) {
    padding: 0 15px;
  }
}
