@import "./styles/_styles.scss";
.divider {
  content: '';
  padding: 16px 0;
  width: 100%;
  height: 0.1px;
  @media screen and(max-width:768px) {
    padding: 12px 0;
  }
}
