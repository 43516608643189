@import "./styles/_styles.scss";
.videoPlayerWrap {
  padding: 32px 173px 32px 173px;
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}
.videoPlayer {
  width: 1094px;
  height: 544px;
  margin: 0 auto;
  border-radius: 10px;
  max-width: 100%;
}

@media screen and(max-width:768px) {
  .videoPlayerWrap {
    padding: 20px 24px 20px 24px;
  }
  .videoPlayer {
    height: 358px;
  }
}
@media screen and(max-width:576px) {
  .videoPlayerWrap {
    padding: 20px 15px 20px 15px;
  }
  .videoPlayer {
    height: 170px;
  }
}
