@import "./styles/_styles.scss";
.heroMoneyFlowPageWrapper {
  position: relative;
}

.videoWrap {
  padding-bottom: 32px;
}
.heroMoneyHeroContainer {
  width: 1440px;
  padding: 0 60px;
  max-width: 100%;
  margin: 80px auto;
  position: relative;
  max-height: 646px;

  display: grid;
  grid-template:
    'top center right'
    'bottom center right';
  grid-template-columns: 23.55% 24.8% 43.8%;

  justify-content: space-between;
  row-gap: 34px;
  :global {
    .image {
      &First {
        grid-area: top;
        width: 100%;
      }
      &Second {
        grid-area: bottom;
        width: 100%;
      }
      &Thirst {
        grid-area: center;
        width: 100%;
      }
      &Fourth {
        grid-area: right;
        width: 100%;
      }
    }
  }
  @media screen and(max-width:1200px) and (min-width: 769px) {
    row-gap: 24px;
  }
  @media screen and(max-width:768px) {
    margin: 48px auto;
    row-gap: 16px;
    padding: 0 25px;
  }
  @media screen and(max-width:576px) {
    margin: 16px auto 32px auto;
    padding: 0 15px;
    grid-template:
      'top top'
      'top top'
      'center right'
      'bottom right';
    grid-template-columns: 46% 48%;
    max-height: fit-content;
    row-gap: 17px;
    :global {
      .image {
        &First {
          grid-area: center;
          width: 100%;
        }
        &Second {
          grid-area: bottom;
          width: 100%;
        }
        &Thirst {
          grid-area: right;
          width: 100%;
        }
        &Fourth {
          grid-area: top;
          width: 100%;
        }
      }
    }
  }
}

.heroTitle {
  font-weight: bold;
  font-size: 80px;
  line-height: 112px;
  text-align: center;
  margin: 0;
  padding-top: 232px;
}
.intro {
  max-width: 876px;
  margin: 0 auto;
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: column;
}
.introTitle {
  margin-right: auto;
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  text-align: left;
  margin-bottom: 33px;
  p {
    margin: 0;
  }
}
.introDescription {
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  max-width: 539px;
  padding-bottom: 92px;
  margin: 0;
  margin-left: auto;
  p {
    margin: 0;
  }
  &.heroHadChildren {
    padding-bottom: 48px;
  }
}
@media screen and(max-width:1200px) {
  .heroTitle {
    padding-top: 200px;
  }
}
@media screen and(max-width:768px) {
  .heroTitle {
    padding-top: 181px;
    font-size: 40px;
    line-height: 52px;
  }
  .intro {
    max-width: 533px;
  }
  .introTitle {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 16px;
  }
  .introDescription {
    font-size: 16px;
    line-height: 24px;
    max-width: 350px;
    padding-bottom: 48px;
    &.heroHadChildren {
      padding-bottom: 12px !important;
    }
  }
  .videoWrap {
    padding-bottom: 18px;
  }
}
@media screen and(max-width:576px) {
  .heroTitle {
    padding-top: 135px !important;
    text-align: left;
    padding-left: 15px;
  }
  .intro {
    padding: 0 15px;
  }
  .introTitle {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 7px;
  }
  .introDescription {
    font-size: 16px;
    line-height: 24px;
  }
}
