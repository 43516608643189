@import "./styles/_styles.scss";
@import '../../../../styles/color.scss';
$carousel-prefix: overlayFlowCarousel;

.#{$carousel-prefix} {
  overflow: hidden;
  padding: 69px 0;
  width: 100%;
  position: relative;
  * {
    font-family: Italian Plate No2 Expanded;
  }

  &Header {
    margin-bottom: 32px;
    padding: 0 63px;
    width: 100%;
  }

  &Content {
    display: flex;
    height: 862px;
    position: relative;

    .overlay {
      left: 0;
      margin: 0 63px;
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;

      &Title {
        font-size: 112px;
        font-weight: 700;
        line-height: 82px;
        margin-bottom: 40px;
        max-width: 835px;
        pointer-events: initial;
        a {
          color: #000000;
          text-decoration: none;
        }
        &Button {
          vertical-align: super;
          button {
            height: 37px !important;
            width: 37px !important;
          }
        }
      }
      &Description {
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        margin-bottom: 34px;
        max-width: 440px;
        pointer-events: initial;
        p {
          margin: 0;
        }
      }
      &Items {
        border-bottom: 2px solid #000000;
        font-size: 32px;
        font-weight: 700;
        line-height: 40px;
        max-width: 425px;
        pointer-events: initial;
        width: 100%;

        .overlayItem {
          align-items: center;
          border-top: 2px solid #000000;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          line-height: 44px;
          padding: 13px 0;
          transition: height 0.3s ease;
          span {
            line-height: 44px;
          }
          p {
            sup {
              line-height: 36px;
            }
            height: 44px;
            line-height: 44px;
          }
          &Active {
            background-color: #b2fce4;
            transition: background-color 0.3s ease-in-out;
            border-radius: 0px;
            height: 44px;
            left: 0px;
            padding: 0 8px;
            top: 84px;
            width: fit-content;
          }
          &Hide {
            border: none !important;
            height: 0;
            margin: 0 !important;
            overflow: hidden;
            padding: 0 !important;
            transition: height 0.3s ease;
          }
        }
      }
    }
    .carousel {
      margin-top: auto;
    }
  }

  @media only screen and (max-width: 1200px) {
    &Content {
      display: block;
      height: auto;
      padding-left: 47px;
      padding-right: 47px;
      .overlay {
        margin: 0;
        margin-bottom: 32px;
        max-width: unset;
        padding: 0 16px;
        position: relative;

        &Items {
          max-width: unset;
        }
      }

      .carousel {
        width: 100vw;
        margin-left: calc(50% - 50vw);
      }
    }
  }
  @media only screen and (max-width: 979px) {
    padding: 33px 0 10px 0;

    &Header {
      margin-bottom: 27px;
      padding: 0 16px;
    }

    &Content {
      display: block;
      height: auto;
      padding-left: unset;
      padding-right: unset;
      .overlay {
        margin: 0;
        margin-bottom: 32px;
        max-width: unset;
        padding: 0 16px;
        position: relative;
        &Items {
          border-bottom: 1px solid #000000;
        }
        &Title {
          font-size: 40px;
          line-height: 48px;
          margin-bottom: 15px;
          &Button {
            margin-left: 6px;
            vertical-align: text-bottom;
            button {
              height: 23px !important;
              width: 23px !important;
            }
          }
        }
        &Description {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 17px;
        }
        &Items {
          font-size: 24px;
          line-height: 36px;
          max-width: unset;

          .overlayItem {
            border-top: 1px solid #000000;
            line-height: 36px;
            padding: 13px 0;
            span {
              line-height: 36px;
            }
            p {
              sup {
                line-height: 24px;
              }
              height: 36px;
              line-height: 36px;
            }
            &Active {
              height: 36px;
              padding-top: 2px;
              line-height: 36px;
              display: inherit;
            }

            button {
              height: 23px !important;
              width: 23px !important;
            }
          }
        }
      }
    }
  }
  @media only screen and(min-width: 1565px) {
    &Content {
      .overlay {
        max-width: 1440px;
        margin-left: 0;
        margin-right: 0;
        left: calc(50vw - 720px);
      }
    }
  }
}
.textInnerHtml {
  sup {
    font-size: 12px;
  }
  P {
    line-height: 36px;
    height: 36px;
    margin: 0;
  }
}
