@import "./styles/_styles.scss";
.moneySiteCarouselWarp {
  position: relative;
}

.carouselHeader {
  padding: 64px 60px 56px 60px;
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  &Title {
    font-size: 80px;
    line-height: 112px;
    font-weight: bold;
    margin: 82px 0 8px 0;
  }
  &Description {
    font-size: 24px;
    line-height: 32px;
    margin: 0;
    margin-bottom: 24px;
    p {
      margin: 0;
    }
  }
}

@media screen and(max-width:768px) {
  .carouselHeader {
    padding: 64px 24px 54px 24px;
    &Title {
      font-size: 56px;
      line-height: 64px;
      margin-top: 34px;
      margin-bottom: 16px;
    }
    &Description {
      font-size: 20px;
      line-height: 28px;
    }
  }
}
@media screen and(max-width:576px) {
  .carouselHeader {
    padding: 32px 15px;
    &Title {
      font-size: 40px;
      line-height: 52px;
      margin-bottom: 10px;
      max-width: 343px;
    }
    &Description {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }
}
