@import "./styles/_styles.scss";
.singleImageWrap {
  width: 1096px;
  max-width: 100%;
  margin: 0 auto;
  padding: 32px 0;
}
@media screen and(max-width:768px) {
  .singleImageWrap {
    padding: 20px 0px;
    width: 533px;
  }
}
@media screen and(max-width:576px) {
  .singleImageWrap {
    width: 320px;
  }
}
