@import "./styles/_styles.scss";
.wrapper {
  align-items: center;
  display: flex;
  font-size: 14px;

  font-style: normal;
  font-weight: bold;
  justify-content: space-between;
  line-height: 22px;
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  .category {
    color: #000000;
    background-color: white;
    border-radius: 20px;
    padding: 8px 20px 7px;
    text-align: center;
    text-decoration: none;
  }
  .number {
    background-color: white;
    border-radius: 50%;
    height: 37px;
    width: 37px;
    text-align: center;
    padding: 8px 10px 7px;
  }
}
