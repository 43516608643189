@import "./styles/_styles.scss";
.moneySiteExplore {
  padding: 64px 60px;
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}
.title {
  font-weight: bold;
  font-size: 56px;
  line-height: 64px;
  margin-top: 60px;
  margin-bottom: 0 0 50px 0;
}
.article {
  &Item {
    width: 100%;
    position: relative;
    z-index: 0;
  }

  &Title {
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    margin: 32px 0 12px 0;
  }
  &Description {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 36px 0;
    p {
      margin: 0;
    }
  }
}
.articleExplore {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  max-width: 100%;
}
.buttonReadMore {
  height: fit-content;
}
@media screen and(max-width:786px) {
  .moneySiteExplore {
    padding: 64px 24px 57px 24px;
  }
  .title {
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 30px;
  }
  .article {
    &Title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 0;
    }
  }
}
@media screen and(max-width:576px) {
  .moneySiteExplore {
    padding: 32px 15px 48px 15px;
  }

  .article {
    &Explore {
      grid-template-columns: 1fr;
      gap: 0;
      flex-direction: column;
      align-items: center;
    }
    &Item {
      margin-right: 0 !important;
      width: 100%;
    }
    &Item:not(:last-of-type) {
      margin-bottom: 20px;
    }
    &Description {
      display: none;
    }
  }
  .buttonReadMore {
    display: none;
  }
}
