@import "./styles/_styles.scss";
.articleThumbnails {
  padding: 0 60px 64px 60px;
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  overflow: visible;
  position: relative;
  &Title {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.articleSwiperContainer {
  :global {
    .swiper-slide {
      padding-top: 8px;
      width: 314px;
    }
  }

  .article {
    &Title {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      margin: 25px 0 15px 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &Description {
      font-size: 16px;
      line-height: 24px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      p {
        margin: 0;
      }
    }
  }
}
@media screen and(min-width:1440px) {
  .articleSwiperContainer {
    overflow: visible;
  }
}
@media screen and (max-width: 1200px) {
  .articleSwiperContainer {
    width: 100vw;
    min-width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    padding-left: 60px;
  }
}
@media screen and(max-width:786px) {
  .articleThumbnails {
    padding: 0 24px 64px 24px;
  }
  .articleSwiperContainer {
    padding-left: 24px;
    :global {
      .swiper-slide {
        width: 250px;
      }
    }
  }
  .article {
    &Title {
      margin: 20px 0;
    }
  }
}
@media screen and(max-width:576px) {
  .articleThumbnails {
    padding: 0 15px 48px 15px;
  }
  .articleSwiperContainer {
    padding-left: 15px;
  }
}
