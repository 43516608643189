@import "./styles/_styles.scss";
.articleTextLargeWrap {
  max-width: 100%;
  width: 870px;
  margin: 0 auto;
  font-size: 40px;
  line-height: 52px;
  padding: 32px 0;
  p {
    margin: 0;
  }
  a {
    color: inherit;
  }
  @media screen and(max-width:768px) {
    font-size: 24px;
    line-height: 32px;
    width: 533px;
    padding: 20px 0;
  }
  @media screen and(max-width:576px) {
    padding: 20px 15px;
  }
}
