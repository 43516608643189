@import "./styles/_styles.scss";
@import '../../../styles/color.scss';

@mixin styling($slide-img-width, $slide-img-heigh, $btn-navigate-size) {
  .carouselWrapper {
    position: relative;
  }
  .swiperContainer {
    max-width: 1520px; // full width
    width: 100%;
    :global(.swiper-slide-active) {
      img {
        box-shadow: 0px 50px 50px rgba(0, 0, 0, 0.1);
        transition: box-shadow 0.3s ease-in-out;
      }
    }
    :global(.swiper-slide) {
      width: $slide-img-width !important;
      height: 725px !important;
      img {
        width: $slide-img-width !important;
        height: $slide-img-height !important;
      }
      @media screen and (max-width: 768px) {
        height: 430px !important;
        img {
          height: 320px !important;
        }
      }
    }
  }

  .carouselNavigate {
    position: absolute;
    z-index: 999;
    width: $btn-navigate-size;
    height: $btn-navigate-size;
    top: calc($slide-img-height/2 - $btn-navigate-size);
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .carouselNavigatePrev {
    left: calc(50% - $slide-img-width/2 - $btn-navigate-size - 10px);
  }
  .carouselNavigateNext {
    right: calc(50% - $slide-img-width/2 - $btn-navigate-size - 10px);
  }
}

$slide-img-width: 427px;
$slide-img-height: 600px;
$btn-navigate-size: 60px;
@include styling($slide-img-width, $slide-img-height, $btn-navigate-size);

@media screen and (max-width: 768px) {
  $slide-img-width: 227px;
  $slide-img-height: 430px;
  $btn-navigate-size: 60px;
  @include styling($slide-img-width, $slide-img-height, $btn-navigate-size);
}
