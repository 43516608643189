@import "./styles/_styles.scss";
.callOutBannerWrapper {
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  width: 1440px;
  padding: 80px 0;
  &.small {
    padding: 32px 0;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
  }
}
.callOutBanner {
  background: #fefec7;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1096px;
  max-width: 100%;
  margin: 0 auto;
  max-height: 307px;
  position: relative;
  &:hover {
    .iconArrowRight {
      &:not(:disabled) {
        div:nth-of-type(2) {
          top: 0;
        }
        div:last-of-type {
          top: -100%;
        }
      }
    }
  }
}
.infoDetail {
  padding: 24px;
}
.title {
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  margin-bottom: 42px;
  p {
    margin: 0;
  }
}
.benefit {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  list-style-type: '\2726';
  li {
    padding-left: 9px;
    p {
      margin: 0;
    }
    sup {
      font-size: 9px;
      line-height: 10px;
    }
  }

  padding-left: 15px;
}
.qrCodeContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.qrBoxImage {
  width: 426px;
  a {
    display: none;
  }
}
.qrCode {
  width: 100%;
  transition: all 0.3s ease-in;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  max-height: 307px;
}
.buttonRight {
  margin-bottom: 22px;
  margin-right: 26px;
  button {
    height: 38px;
    width: 38px;
  }
}
.iconAppleStore {
  display: none;
}

@media screen and(max-width:1200px) {
  .callOutBannerWrapper {
    padding: 64px 0;
  }
}

@media screen and(max-width:996px) {
  .callOutBannerWrapper {
    padding: 32px 0 48px 0;
  }
  .callOutBanner {
    flex-wrap: wrap;
    height: fit-content;
    max-height: 100%;
    max-width: 343px;
    padding: 26px;
  }
  .infoDetail {
    padding: 0;
  }
  .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 13px;
  }
  .benefit {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 45px;
    padding-left: 12px;
  }
  .qrBoxImage {
    display: none;
  }
  .qrCodeContainer {
    width: 100%;
  }
  .buttonRight {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    button {
      height: 38px !important;
      width: 38px !important;
    }
  }
  .iconAppleStore {
    display: block;
    width: 147px;
    height: 45px;
    svg {
      width: 147px;
      height: 45px;
    }
  }
}
