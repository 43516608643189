@import "./styles/_styles.scss";
.merchantBanner {
  position: relative;
  margin: 32px auto;
  max-width: 100%;
  width: 1096px;
  background: #fff080;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &:hover {
    button {
      &:not(:disabled) {
        div:nth-of-type(2) {
          top: 0;
        }
        div:last-of-type {
          top: -100%;
        }
      }
    }
  }
}
.merchantLink {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.infoDetail {
  padding: 32px 0 30px 26px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 390px;
}
.title {
  font-weight: bold;
  font-size: 40px;
  line-height: 52px;
  p {
    margin: 0;
  }
}
.description {
  font-size: 16px;
  line-height: 24px;
  p {
    margin: 0;
  }
}
.imageBox {
  width: 425px;
}
.merchantLogoImage {
  width: 118px;
  position: absolute;
  top: 32px;
}
.imageContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.buttonRight {
  margin-bottom: 22px;
  margin-right: 26px;
  height: 38px;
  width: 38px;
  button {
    height: 38px;
    width: 38px;
  }
}

@media screen and( max-width:768px) {
  .merchantBanner {
    border-radius: 10px;
    width: 535px;
  }
  .imageBox {
    width: 207px;
  }
  .infoDetail {
    padding: 15px 0 11px 12px;
  }
  .merchantLogoImage {
    width: 58px;
    top: 15px;
  }
  .title {
    font-size: 24px;
    line-height: 32px;
  }
  .description {
    font-size: 14px;
    line-height: 22px;
  }
  .buttonRight {
    margin-right: 15px;
    margin-bottom: 12px;
    height: 32px;
    width: 32px;
    button {
      height: 32px !important;
      width: 32px !important;
    }
  }
}
@media screen and( max-width:576px) {
  .merchantBanner {
    border-radius: 20px;
    padding: 37px 37px 33px 23px;
    width: 343px;
    min-height: 324px;
  }
  .infoDetail {
    padding: 0;
    justify-content: flex-start;
  }
  .title {
    font-size: 32px;
    line-height: 40px;
  }
  .description {
    font-size: 16px;
    line-height: 24px;
  }
  .imageBox {
    display: none;
  }
  .merchantLogoImage {
    top: unset;

    bottom: 33px;
    width: 118px;
  }
  .buttonRight {
    margin-bottom: 0px;
    margin-right: 0px;
    height: 38px !important;
    width: 38px !important;
    button {
      height: 38px !important;
      width: 38px !important;
    }
  }
}
