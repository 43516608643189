@import "./styles/_styles.scss";
.buttonBack {
  margin: 0 auto;
  padding: 32px 0 128px 0;
  display: block;
  span {
    width: auto !important;
  }
  @media screen and(max-width:768px) {
    padding: 20px 0 48px;
  }
}
