@import "./styles/_styles.scss";
.c-background-zoom {
  position: relative;
  border-radius: 20px;
  .image-box {
    width: 100%;
    padding-top: 100%;
    background: transparent;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s;
    transform: rotate(0);
    border-radius: inherit;
    overflow: hidden;
    z-index: 1;
    .image-main {
      width: 100%;
      transition: all 0.3s ease-in;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: #c1e7bb;
    }
  }
  &:hover {
    .image-box {
      -webkit-transform: translate(-10px, -10px);
      -moz-transform: translate(-10px, -10px);
      -o-transform: translate(-10px, -10px);
      -ms-transform: translate(-10px, -10px); /* IE 9 */
      transform: translate(-10px, -10px);
    }
    transition: filter 0.3s;
    filter: none;
  }
}
.colorless {
  filter: grayscale(100%);
}
.imageLink {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.cursor {
  cursor: pointer;
}
.imageBreakDown {
  background: #f0f0f0;
  cursor: unset;
}
@media screen and(max-width:768px) {
  .c-background-zoom {
    border-radius: 10px;
  }
}
