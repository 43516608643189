@import "./styles/_styles.scss";
.articleMeta {
  width: 870px;
  max-width: 100%;
  margin: 0 auto;
  padding: 32px 0;
}
.articleMeta {
  &Container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 37px;
    :global {
      .btn-share-instagram {
        svg {
          height: 19px !important;
        }
      }
    }
  }
  &Tags {
    a,
    div {
      cursor: default;
      pointer-events: none;
    }
  }
}
.shareLinks {
  display: flex;
  .buttonWrap {
    position: relative;
    &:hover {
      .tooltip {
        visibility: visible !important;
        opacity: 1 !important;
      }
    }
  }
  .buttonWarp:not(:last-of-type) {
    margin-right: 10px;
  }
}

@media screen and(max-width:768px) {
  .articleMeta {
    padding: 20px 0;
    width: 533px;
  }
  .articleMetaContainer {
    margin-top: 32px;
  }
  .tooltip{
    display: none;
  }
}
@media screen and(max-width:576px) {
  .articleMetaContainer {
    padding: 0 15px;
  }
  .shareLinks {
    margin-top: 24px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 345px;
  }
}
.buttonWrap {
  position: relative;
  &:hover {
    .tooltip {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }
}
.tooltip {
  visibility: hidden;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 5px;
  position: absolute;
  margin-top: -41px;
  margin-left: -80px;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  white-space: nowrap;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -25px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
}
