@import "./styles/_styles.scss";
.separationLine {
  content: '';
  margin: 28px auto;
  max-width: 100%;
  width: 870px;
  background-color: black;
  height: 0.1px;

  @media screen and(max-width:768px) {
    width: 533px;
  }
  @media screen and(max-width:576px) {
    margin: 0 15px;
    width: auto;
  }
}
