@import "./styles/_styles.scss";
.articleTextWrap {
  text-align: left;
  margin: 0 auto;
  padding: 32px 0;
  max-width: 100%;
  width: 870px;
}
.verticalLine {
  height: 0.1px;
  margin-bottom: 18px;
  background-color: #000;
}
.articleText {
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  &.small {
    font-size: 14px !important;
    line-height: 22px !important;
  }
  &.colorless {
    color: #999 !important;
  }
  p {
    margin: 0;
  }
  a {
    color: inherit;
  }
  sup {
    font-size: 9px;
    line-height: 10px;
  }
}

.title {
  &Bold {
    margin: 0;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 24px;
  }
  &Large {
    margin-bottom: 18px;
    div {
      padding: 0;
    }
  }
}

@media screen and(max-width:768px) {
  .articleTextWrap {
    padding: 20px 0;
    width: 533px;
  }
  .articleText {
    font-size: 16px;
    line-height: 24px;
  }

  .title {
    &Bold {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 10px;
    }
    &Large {
      margin-bottom: 33px;
    }
  }
}
@media screen and(max-width:576px) {
  .articleTextWrap {
    padding: 20px 15px;
  }
}
