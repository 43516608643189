@import "./styles/_styles.scss";
$loader-size: 30px;
.vimeoEmbedContainer {
  max-width: 100%;
  width: 100%;
  height: 100%;
}
.vimeoVideo {
  position: relative;
  border-radius: 20px;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.CVideo {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
.c-video-loader {
  display: none;
  width: $loader-size;
  height: $loader-size;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  top: 50%;
  margin-top: calc(var(-$loader-size) / 2);
  border: 5px solid #f3f3f3;
  animation: video-spin 1s linear infinite;
  border-top: 5px solid #555;
  border-radius: 50%;
}
.c-video-loading {
  background: #ffffff;
  .c-video-loader {
    display: block;
  }
}
@media screen and(max-width:768px) {
  .vimeoVideo {
    border-radius: 10px;
  }
}
/* Safari */
@-webkit-keyframes video-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes video-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
