@import "./styles/_styles.scss";
.twoColImage.large {
  max-width: 100%;
  margin: 0 auto;
  padding: 32px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 1096px;
  .imageCol {
    width: 538px;
    &:first-of-type {
      margin-right: 20px;
    }
  }
  @media screen and(max-width:768px) {
    .articleMeta {
      padding: 20px 0;
    }
    .imageCol {
      width: 257px;
    }
  }
  @media screen and(max-width:576px) {
    flex-wrap: wrap;
    padding: 20px 15px;

    .imageCol {
      width: 100%;
      &:first-of-type {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
}
.twoColImageWrap.small {
  width: 870px;
  max-width: 100%;
  margin: 0 auto;
}
.twoColImage.small {
  max-width: 100%;
  margin-left: 0;
  padding-bottom: 32px;
  width: 762px;
  display: grid;
  grid-template-columns: auto auto;
  justify-items: center;
  justify-content: center;
  gap: 20px;
  h2 {
    margin-bottom: 24px;
  }

  .imageCol {
    width: 372px;
    margin-top: 16px;
  }
  @media screen and (max-width: 768px) {
    .imageCol {
      width: 259px;
    }
  }

  @media screen and(max-width:576px) {
    padding-bottom: 20px;

    .imageCol {
      width: 167px;
      margin-top: 20px;
    }
    .twoColImage {
      padding: 0 15px;
      gap: 9px;
    }
  }
}
