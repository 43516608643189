@import "./styles/_styles.scss";
.articleTitle {
  max-width: 100%;
  width: 1440px;
  margin: 0 auto;
  padding: 0 60px 32px 60px;
  display: flex;
  align-items: flex-start;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.buttonBack {
  span {
    width: auto !important;
  }
}

.titleContainer {
  margin: 0;
  margin-left: 126px;
  max-width: 100%;
  & > div:first-child {
    margin-bottom: 24px;
  }
}
.partnership {
  position: unset !important;
  margin: 0 !important;
}
.title {
  font-weight: bold;
  font-size: 80px;
  line-height: 110%;
  margin: 0 0 24px;
  p {
    margin: 0;
  }
}

.partnership {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.logoPartner {
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 30px;
  max-width: 152px;
  width: 152px;
  height: 30px;
  margin-left: 11px;
}
@media screen and(min-width:769px) {
  .buttonBack.headerNoTags {
    margin-top: 21px;
  }
}
@media screen and(max-width:768px) {
  .articleTitle {
    padding: 40px 25px 20px 25px;
  }
  .buttonBack.headerNoTags {
    margin-top: 10px;
  }
  .titleContainer {
    margin: 0;
  }

  .title {
    font-size: 40px;
    line-height: 52px;
  }
}
@media screen and(max-width:576px) {
  .articleTitle {
    padding: 0 15px 16px 15px;
    margin-top: 24px;
    flex-direction: column;
    position: relative;
  }
  .buttonBack.headerNoTags {
    margin-top: 0;
  }
  .buttonContainer {
    margin-bottom: 32px;
  }

  .titleContainer {
    margin: 0;

    &.headerNoTags {
      & > div:first-of-type {
        position: inherit !important;
      }
    }
  }

  .title {
    margin-bottom: 0 !important;
  }
}
