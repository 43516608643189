@import "./styles/_styles.scss";
.imageListWrap {
  width: 870px;
  margin: 0 auto;
  max-width: 100%;
}
.imageList {
  margin-left: 0;
  width: 762px;
  max-width: 100%;
  padding: 32px 0;
}
.title {
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  margin-top: 32px;
  margin-bottom: 24px;
}
.description {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
}
.imageListItem {
  display: grid;
  grid-template-columns: 100px auto;
  padding: 32px 0;
  gap: 40px;
  align-items: center;
  border-top: 1px solid #808080;
  border-bottom: 1px solid #808080;
  :global {
    .image-item-list {
      height: 100px;
      width: 100px;
    }
  }
  p {
    margin: 0;
  }
}
.itemContent {
  font-size: 20px;
  line-height: 28px;
}
.itemTitle {
  font-weight: bold;
}
.itemDescription p {
  margin: 0;
}
@media screen and (max-width: 768px) {
  .title {
    margin-top: 4px;
    font-size: 24px;
    line-height: 32px;
  }
  .description {
    font-size: 16px;
    line-height: 24px;
  }
  .imageListWrap {
    width: 533px;
  }
  .itemContent {
    font-size: 16px;
    line-height: 24px;
  }
  .imageListItem {
    grid-template-columns: 70px auto;
    gap: 24px;
    :global {
      .image-item-list {
        height: 70px;
        width: 70px;
      }
    }
  }
}
@media screen and(max-width:576px) {
  .imageList {
    padding: 32px 15px;
  }
  .title,
  .description {
    padding: 0 15px;
  }

  .imageListItem {
    align-items: flex-start;
    grid-template-columns: 50px auto;
    gap: 24px;
    :global {
      .image-item-list {
        height: 50px;
        width: 50px;
      }
    }
  }
}
