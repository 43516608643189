@import "./styles/_styles.scss";
$loader-size: 30px;

.c-video-container {
  position: relative;
  .c-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    &::-webkit-media-controls {
      visibility: hidden;
    }
    &::-webkit-media-controls-enclosure {
      visibility: visible;
    }
  }
  .c-video-loader {
    display: none;
    width: $loader-size;
    height: $loader-size;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    top: 50%;
    margin-top: calc(var(-$loader-size) / 2);
    border: 5px solid #f3f3f3;
    animation: video-spin 1s linear infinite;
    border-top: 5px solid #555;
    border-radius: 50%;
  }
  &.c-video-loading {
    background: #ffffff;
    .c-video-loader {
      display: block;
    }
  }

  .video-controls.show {
    visibility: visible;
    opacity: 1;
  }

  .video-controls {
    -webkit-transition: opacity 700ms, visibility 700ms;
    transition: opacity 700ms, visibility 700ms;
    visibility: hidden;
    position: absolute;

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    z-index: 3;
    &__btnPlayPause {
      transition: all 0.5s ease-in-out;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
    &__btnMuted {
      transition: all 0.5s ease-in-out;
      position: absolute;
      top: 16px;
      right: 16px;
      z-index: 2;
      cursor: pointer;
    }
    &__btnTopRight {
      transition: all 0.5s ease-in-out;
      position: absolute;
      top: 23px;
      left: 16px;
      z-index: 4;
      cursor: pointer;
      @media screen and( min-width:768px) {
        .icon-back {
          display: none;
        }
      }
      @media screen and( max-width:768px) {
        .icon-close {
          display: none;
        }
      }
    }
  }
}
.buttonPlay {
  height: 75px;
  button {
    height: 75px !important;
    width: 75px !important;
    svg {
      height: 22px !important;
      width: 22px !important;
      max-height: 22px !important;
      max-width: 22px !important;
    }
  }

  span {
    color: white !important;
    text-decoration: none !important;
    font-weight: bold;
    font-size: 33.1918px;
    line-height: 43px;
  }
}
.controlOverLay {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border-radius: inherit;
  transition: opacity 0.3s ease-in-out;
  &.hide {
    opacity: 0;
  }
  &.show {
    opacity: 1;
  }
}
@media screen and(max-width:768px) {
  .buttonPlay {
    height: 40px;

    button {
      height: 40px !important;
      width: 40px !important;
      svg {
        height: 12px !important;
        width: 12px !important;
        max-height: 12px !important;
        max-width: 12px !important;
      }
    }

    span {
      font-size: 20px;
      line-height: 28px;
    }
  }
}
@media screen and(max-width:576px) {
  .buttonPlay {
    height: 40px;

    button {
      height: 30px !important;
      width: 30px !important;
      svg {
        height: 10px !important;
        width: 10px !important;
        max-height: 10px !important;
        max-width: 10px !important;
      }
    }

    span {
      font-size: 20px;
      line-height: 28px;
    }
  }
}
/* Safari */
@-webkit-keyframes video-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes video-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
