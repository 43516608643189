@import "./styles/_styles.scss";
ol.articleOrderedList {
  font-size: 20px;
  line-height: 28px;
  width: 810px;
  max-width: 100%;
  margin: 0 auto;
  padding: 32px 0;
  list-style-type: none;
  counter-reset: listStyle;
  position: relative;
}
.orderedListChild {
  margin-top: 16px;
  display: block;
  flex: 1;
  list-style-type: none;
  counter-reset: listStyle;
  grid-column: span 2;
}
li.orderedListItem {
  display: grid;
  grid-template-columns: 35px auto;
  counter-increment: listStyle;
  max-width: 719px;
  &::before {
    content: '(' counter(listStyle, lower-alpha) ')\00a0\00a0\00a0';
  }
}
li.orderedListChildItem {
  counter-increment: listStyle;
  display: flex;
  &::before {
    content: '(' counter(listStyle, lower-roman) ')\00a0\00a0\00a0\00a0\00a0';
  }
}
.orderedListItem:not(:last-of-type),
.orderedListChildItem:not(:last-of-type) {
  margin-bottom: 8px;
}
@media screen and(max-width:768px) {
  .articleOrderedList {
    padding: 20px 0;
  }
}
@media screen and(max-width:576px) {
  .articleOrderedList {
    padding: 20px 15px;
    width: 345px !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }
}
