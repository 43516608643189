@import "./styles/_styles.scss";
@import '../../../styles/color.scss';

@mixin get-side-background($color, $direction) {
  background: linear-gradient(
    to $direction,
    $color 0%,
    rgba($color: $color, $alpha: 0.01) 99%
  );
}

.container {
  max-width: 1440px;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 0 auto;
  .right {
    margin-left: 0;
    position: relative;

    //  Keep the right side
    @media only screen and (min-width: 575px) {
      left: 0;
      margin-left: calc(100vw - 1239px);
      transform: none;
      width: calc(1440px + 546px);
    }
    @media only screen and (min-width: 768px) {
      margin-left: calc(100vw - 1478px);
    }
    @media only screen and (min-width: 1479px) {
      margin-left: 0;
    }
  }
  @media only screen and (min-width: 1440px) {
    max-width: 100%;
    .right {
      width: 100%;
      margin-left: calc(50vw - 50% + 250px);
    }
  }
  .center {
    .Content {
      width: 100vw;
      min-width: 100vw;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
    }
  }
  .Content {
    overflow: visible;
    padding: 15px 0 50px 0;
    @media only screen and (max-width: 575px) {
      padding: 11px 0 22px 0;
    }
    @media only screen and (min-width: 575px) {
      padding: 5px 0 22px 0;
    }

    @media only screen and (min-width: 768px) {
      padding: 15px 0 50px 0;
    }

    .Iphone {
      left: 50%;
      pointer-events: none;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      &Mock {
        height: 697.07px;
        z-index: 1;
        img {
          height: 100%;
        }
      }
      &Shadow {
        height: 727.85px !important;
        left: calc(50% + 19px);
        top: 10px;
        width: 357.72px;
        z-index: 0;
        img {
          height: 100%;
        }
      }

      > svg {
        height: 698.07px;
      }

      @media only screen and (min-width: 575px) {
        &Mock {
          height: 437.32px;
          > svg {
            height: 437.32px;
          }
        }
        &Shadow {
          height: 458.22px !important;
          top: 5px;
          width: 228.49px;
        }
      }
      @media only screen and (min-width: 768px) {
        &Mock {
          height: 698.07px;
          > svg {
            height: 698.07px;
          }
        }

        &Shadow {
          height: 727.85px !important;
          top: 10px;
          width: 357.72px;
        }
      }
      @media screen and(max-width:576px) {
        &Mock {
          height: 488.15px;
          > svg,
          img {
            height: 488.15px;
          }
        }

        &Shadow {
          height: 489.15px !important;
          top: 5px;
          width: auto;
        }
      }
    }

    .Navigate {
      display: none !important;
      position: absolute !important;
      top: 50% !important;
      transform: translateY(-50%) !important;
      z-index: 999;
    }

    @media only screen and (min-width: 768px) {
      .Navigate {
        display: block !important;
        &Next {
          margin-right: calc(50% - 279px);
          right: 0;
        }
        &Prev {
          svg {
            margin-right: 6px;
          }
          left: 0;
          margin-left: calc(50% - 279px);
        }
      }
    }

    :global {
      .swiper {
        height: 100%;
        width: 100%;
        @media only screen and (min-width: 1440px) {
          overflow: visible;
        }

        .swiper-wrapper {
          transition-timing-function: linear;
          user-select: none;
          .swiper-slide {
            transition-duration: 400ms !important;
            width: auto;
            @media screen and(max-width:576px) {
              width: 219px !important;
              height: 466px !important;
              transform: scale(1) !important;
            }
            > div {
              transition: all 300ms ease;
              border-radius: 20px;
              overflow: hidden;
              @media screen and(max-width:576px) {
                div {
                  width: 219px !important;
                  height: 466px !important;
                }
              }
              &.moss-20 {
                background: $moss-20;
              }
              &.moss-40 {
                background: $moss-40;
              }
              &.fire-20 {
                background: $fire-20;
              }
              &.fire-40 {
                background: $fire-40;
              }
              &.orca-20 {
                background: $orca-20;
              }
              &.orca-45 {
                background: $orca-45;
              }

              .img {
                overflow: hidden;
              }
            }

            &:not(.swiper-slide-active) {
              > div {
                filter: grayscale(0.5);
                transform: translateZ(0);
                img {
                  mix-blend-mode: soft-light;
                }
              }
            }
          }
        }
      }
    }
  }
}
