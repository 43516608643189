@import "./styles/_styles.scss";
.iconListWrap {
  width: 870px;
  max-width: 100%;
  margin: 0 auto;
}
.iconListWrapHeader {
  padding-bottom: 24px;
}
.iconList {
  display: grid;
  grid-template-rows: auto;
  gap: 24px;
  list-style: none;
  max-width: 100%;
  padding: 0 0 32px;
  margin: 0;
  &.large {
    width: 762px;
    .itemContent {
      &:hover {
        text-decoration: none;
      }
    }
    .iconListItem {
      align-items: flex-start;
    }
  }
  &.small {
    padding: 0 0 28px;
    width: 533px;
    .iconListItem {
      width: fit-content;
      gap: 16px;
    }
  }
}
.iconListItem {
  display: grid;
  grid-template-columns: repeat(2, minmax(38px, auto));
  gap: 30px;
  align-items: center;
  svg {
    width: 38px;
    height: 38px;
  }
}
.itemContent {
  width: auto;
  text-decoration: none;
  display: block;
  color: #000;
  font-size: 20px;
  line-height: 28px;
  &:hover {
    text-decoration: underline;
  }
}
@media screen and(max-width:768px) {
  .itemContent {
    font-size: 16px;
    line-height: 24px;
  }
  .iconListWrap {
    width: 533px;
  }
}
@media screen and(max-width:576px) {
  .iconList {
    width: 343px;
    padding: 0 15px !important;
  }
}
