$black: #000000;
$bondi-mint: #b2fce4;
$white: #ffffff;
$gray-90: #f0f0f0;
$gray-80: #cccccc;
$gray-40: #666666;
$olive-30: #f6ff50;
$moss-20: #c1e7bb;
$moss-40: #98d79a;
$fire-20: #f8ae89;
$fire-40: #f3744b;
$orca-45: #488fe9;
$orca-40: #5e95d1;
$orca-20: #c0dbe9;

$money-flow-gradient: linear-gradient(
    180deg,
    #62aa7e -6.61%,
    #9ceb6b 41.1%,
    #f6ff50 100%
  )
  border-box;
$super-powers-gradient: linear-gradient(180deg, #f15e7d -28.67%, #ffed57 83.83%)
  border-box;
$feature-you-gradient: linear-gradient(
    180deg,
    #8c74cf 0%,
    #5e95d1 26.59%,
    #b5e9ff 104.19%
  )
  border-box;
