@import "./styles/_styles.scss";
@import '../../styles/color.scss';
.singleImage {
  position: relative;
  border-radius: 20px;
  cursor: pointer;
  &:before {
    content: '';
    position: absolute;
    border-radius: inherit;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    z-index: -1;
    opacity: 0;
  }
  .image-box {
    width: 100%;
    padding-top: 100%;
    background: transparent;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s;
    transform: rotate(0);
    border-radius: inherit;
    overflow: hidden;
    z-index: 1;
    .image-main {
      border-radius: inherit;
      width: 100%;
      transition: all 0.3s ease-in;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  &.fire::before {
    background: $super-powers-gradient;
  }
  &.moss::before {
    background: $money-flow-gradient;
  }
  &.orca::before {
    background: $feature-you-gradient;
  }

  :global {
    .tag-bottom {
      position: absolute;
      bottom: 24px;
      right: 24px;
      z-index: 1;
    }
    .tag-top {
      z-index: 1;
      position: absolute;
      top: 22px;
      left: 26px;
    }
    @media screen and(max-width:768px) {
      .tag-bottom {
        bottom: 16px;
        right: 16px;
      }
      .tag-top {
        top: 16px;
        left: 16px;
      }
    }
    @media screen and(max-width:576px) {
      .tag-bottom {
        bottom: 8px;
        right: 8px;
      }
    }
  }
}

.imageLink:hover ~ .singleImage,
.singleImage.canHover:hover {
  &:before {
    opacity: 1;
    display: inline-block;
    transition: opacity 0.5s linear;
  }

  .image-main {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
    -ms-transform: scale(1.1); /* IE 9 */
    transform: scale(1.1);
    filter: none;
  }

  .buttonPlay {
    &:not(:disabled) {
      div:nth-of-type(2) {
        top: 0;
      }
      div:last-of-type {
        top: -100%;
      }
    }
  }
  background-position: 0 0;
  transition: all 0.3s linear;
}
.buttonPlay {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  z-index: 2;
}
.blindColor {
  .image-main {
    filter: grayscale(100%);
  }
}
.imageLink {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.tags {
  a {
    z-index: 3 !important;
  }
}
@media screen and(max-width:768px) {
  .singleImage {
    border-radius: 10px;
  }
}
@media screen and(max-width:576px) {
  .buttonPlay {
    height: 40px !important;
    width: 40px !important;
    margin: -20px 0 0 -20px;
    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }
}
