@import "./styles/_styles.scss";
.backgroundFullWidth {
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  height: 100%;
  z-index: -1;
}