@import "./styles/_styles.scss";
.circleThumbnails {
  padding: 0 52px 64px 60px;
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.articleSwiperContainer {
  :global {
    .swiper-slide {
      width: 270px;
      padding-top: 8px;
    }
  }

  .article {
    &Title {
      font-weight: bold;
      font-size: 20px;
      line-height: 28px;
      margin: 33px 0 0 0;
      margin-top: 33px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
  }
}
@media screen and(min-width:1440px) {
  .articleSwiperContainer {
    padding-left: 24px;
    :global {
      .swiper-wrapper {
        width: fit-content;
        margin: 0 auto;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .articleSwiperContainer {
    width: 100vw;
    min-width: 100vw;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    padding-left: 60px;
  }
}
@media screen and(max-width:786px) {
  .circleThumbnails {
    padding: 0 16px 64px 24px;
  }
  .articleSwiperContainer {
    padding-left: 24px;
    :global {
      .swiper-slide {
        width: 250px;
      }
    }
  }
  .articleTitle {
    margin-top: 25px !important;
  }
}
@media screen and(max-width:576px) {
  .circleThumbnails {
    padding: 0 0px 48px 0px;
  }
  .articleSwiperContainer {
    padding-left: 0;
  }
  .articleSwiperContainer {
    :global {
      .swiper-wrapper {
        margin: 0 auto;
        width: fit-content;
        display: grid;
        grid-template-columns: 162px 162px;
        align-items: center;
        justify-items: center;
        gap: 20px;
      }
      .swiper-slide {
        width: 162px;
      }
    }
  }
  .articleTitle {
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px !important;
  }
}
