@import "./styles/_styles.scss";
.articleCarousel {
  padding: 32px 0;
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}
.articleSliderContainer {
  width: 100vw;
  min-width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}
.sliderNavigate {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  z-index: 999;
}
.sliderNavigatePrev {
  left: 18%;
  svg {
    margin-right: 6px;
  }
}
.sliderNavigateNext {
  left: 78%;
}
@media screen and(max-width:1366px) {
  .sliderNavigatePrev {
    left: 16%;
  }
  .sliderNavigateNext {
    left: 80%;
  }
}
@media screen and(max-width:1200px) {
  .sliderNavigatePrev {
    left: 11%;
  }
  .sliderNavigateNext {
    left: 84%;
  }
}
@media screen and(max-width:1024px) {
  .sliderNavigate {
    display: none !important;
  }
}
@media screen and(max-width:768px) {
  .articleCarousel {
    padding: 20px 0;
  }
}
.articleSwiperContainer {
  :global {
    .swiper-slide {
      width: 874px !important;
      @media screen and(max-width:768px) {
        width: 533px !important;
      }
      @media screen and(max-width:576px) {
        width: 320px !important;
      }
    }
    .swiper-slide-active {
      div {
        filter: none !important;
        transition: filter 0.3s ease;
      }
    }
  }
}
