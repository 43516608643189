@import "./styles/_styles.scss";
.articleHeroImage {
  padding: 32px 0;
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
}
.articleSliderContainer {
  width: 100vw;
  min-width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

@media screen and(max-width:768px) {
  .articleHeroImage {
    padding: 20px 0;
  }
}
.articleSwiperContainer {
  :global {
    .swiper-slide {
      width: 1096px !important;

      @media screen and(max-width:768px) {
        width: 627px !important;
      }
      @media screen and(max-width:576px) {
        width: 343px !important;
      }
    }
  }
}
